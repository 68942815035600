import Swal from "sweetalert2";
export default {
    methods:{
        /**
       * This function is used to check if the user is logged in or not where:
       * The function returns true if there is a token stored either in the localStorage or in the sessionStorage
       * The function returns false if there is no token stored in neither the localStoage nor the sessionStorage
       * @public
       */
        error(value){
            Swal.fire({
                icon: 'error',
                // title: `${value.response.data.detail["error"]}`,
                title: `Error !`,
                text: `${(value.response.data.detail["error message"])?value.response.data.detail["error message"]:value.response.data.detail.errors[0]}`,
                confirmButtonClass: "btn btn-confirm mt-2",
                confirmButtonText: 'OK',
                allowOutsideClick: false,
                allowEscapeKey: false,
            });
        },
        errorUpload(value){
            Swal.fire({
                icon: 'error',
                title: `${value.detail["error"]}`,
                text: `${(value.detail["error message"])?value.detail["error message"]:value.detail.errors[0]}`,
                confirmButtonClass: "btn btn-confirm mt-2",
                confirmButtonText: 'OK',
                allowOutsideClick: false,
                allowEscapeKey: false,
            });
        },
        errorMessage(value){
            Swal.fire({
                icon: 'error',
                title: `Error !`,
                // title: `${value.response.data.detail["error"]}`,
                text: `${(value.response.data.detail["message"])?value.response.data.detail["message"]:value.response.data.detail.errors[0]}`,
                confirmButtonClass: "btn btn-confirm mt-2",
                confirmButtonText: 'OK',
                allowOutsideClick: false,
                allowEscapeKey: false,
            });
        },
        errorLogin(value){
            let keys = Object.keys(value.past_logins)
            Swal.fire({
                icon: 'error',
                title: `${value.error}`,
                html: `<p>${value.message }</p>
                         <ul>
                         <li>${value.past_logins[keys[0]]}</li>
                         <li>${value.past_logins[keys[1]]}</li>
                         </li>
                        `,
                confirmButtonClass: "btn btn-confirm mt-2",
                confirmButtonText: 'OK',
                allowOutsideClick: false,
                allowEscapeKey: false,
            });
        },
        errorValue(value){
            Swal.fire({
                icon: 'error',
                title: "Error !",
                text: value,
                confirmButtonClass: "btn btn-confirm mt-2",
                confirmButtonText: 'OK',
                allowOutsideClick: false,
                allowEscapeKey: false,
            });
        },
        successful(value){
            Swal.fire({
                icon: 'success',
                title: "Success",
                text: value,
                confirmButtonClass: "btn btn-confirm mt-2",
                confirmButtonText: 'OK',
                allowOutsideClick: false,
                allowEscapeKey: false,
            });
        },
        announcement(value){
            Swal.fire({
                icon: 'warning',
                title: "Announcement",
                text: value,
                confirmButtonClass: "btn btn-confirm mt-2",
                confirmButtonText: 'OK',
                allowOutsideClick: false,
                allowEscapeKey: false,
            });
        }
    }
};